<template>
  <section class="footer d-flex justify-center">
    <div class="content">
      <v-row>
        <v-col cols="12" sm="8">
          <div>
            <v-img
              src="@/assets/images/Logo_Azollite.png"
              max-width="120px"
              @click="$route.path != '/' ? $router.push('/') : ''"
              style="cursor: pointer"
            ></v-img>
            <div class="icons d-flex mt-12">
              <a
                href="https://www.instagram.com/ecosmartecs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <v-icon>mdi-instagram</v-icon>
              </a>
              <a
                href="https://t.me/EcoSmartECS"
                target="_blank"
                rel="noopener noreferrer"
              >
                <v-icon>mdi-send</v-icon>
              </a>
              <a
                href="https://twitter.com/EcoSmartECS"
                target="_blank"
                rel="noopener noreferrer"
              >
                <v-icon>mdi-twitter</v-icon>
              </a>
              <a
                href="https://www.youtube.com/channel/UCZyavwOXisC7aVVYX8FbnPQ/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <v-icon>mdi-youtube</v-icon>
              </a>
              <a
                href="https://discord.gg/WJKCk6c"
                target="_blank"
                rel="noopener noreferrer"
                class="mt-n1"
              >
                <v-icon>mdi-robot</v-icon>
              </a>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="d-flex flex-column">
            <router-link to="/shop"> Marketplace </router-link>
            <a href="https://telegra.ph/LandsofAzollite-07-03" target="_blank">
              White paper
            </a>

            <a href="/#contact" class="grey--text">Contact</a>
            <router-link :to="{name:'terms-and-conditions'}"> Terms and conditions </router-link>
            <router-link :to="{name: 'privacy-policy'}"> Privacy Policy </router-link>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  padding: 60px 0;
  // background: #100c06;
  background: rgb(9, 9, 9);
  .content {
    width: 85%;

    .icons {
      .v-icon {
        color: white;
        font-size: 25px;
      }
    }
    a {
      color: grey;
      margin-right: 10px;
    }
  }
}
</style>
