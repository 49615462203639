<template>
  <section class="nav-drawer d-flex flex-column align-center">
    <div class="mt-16">
      <div class="items mb-15">
        <h1 style="animation-delay: 0.1s" @click="click('/shop')">
          Marketplace
        </h1>
        <h1 style="animation-delay: 0.2s" @click="click('/map')">Map</h1>

        <h1 style="animation-delay: 0.3s">
          <a class="white--text" href="https://telegra.ph/LandsofAzollite-07-03"
            >White paper</a
          >
        </h1>
        <!-- <h1 style="animation-delay: 0.3s" >Journal</h1> -->
        <a href="/#contact" @click="click('')"
          ><h1 style="animation-delay: 0.4s">Contact</h1></a
        >
      </div>

      <div class="icons d-flex justify-center">
        <a
          href="https://www.instagram.com/ecosmartecs/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon color='gray' style="animation-delay: 0.6s">mdi-instagram</v-icon>
        </a>
        <a
          href="https://t.me/EcoSmartECS"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon color='gray' style="animation-delay: 0.7s">mdi-send</v-icon>
        </a>
        <a
          href="https://twitter.com/EcoSmartECS"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon color='gray' style="animation-delay: 0.8s">mdi-twitter</v-icon>
        </a>
        <a
          href="https://www.youtube.com/channel/UCZyavwOXisC7aVVYX8FbnPQ/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon color='gray' style="animation-delay: 0.9s">mdi-youtube</v-icon>
        </a>
        <a
          href="https://discord.gg/WJKCk6c"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon color='gray' style="animation-delay: 1s">mdi-robot</v-icon>
        </a>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    const body = document.body;
    body.style.cssText = "height:100vh; overflow:hidden;";
  },
  beforeDestroy() {
    const body = document.body;
    body.style.cssText = "height: ''; overflow: '';";
  },
  methods: {
    click(path) {
      this.$emit("click", path);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-drawer {
  position: fixed;
  top: 88px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #38383b;
  div {
    .items {
      cursor: pointer;
      h1 {
        animation: slide-down 1s ease forwards;
        opacity: 0;
        color: white;
        font-size: 40px;
        text-align: center;
        margin-bottom: 30px;
      }
    }
    .icons {
      .v-icon {
        opacity: 0;
        animation: slide-down 1s ease forwards;
      }
    }
  }
}
@keyframes slide-down {
  0% {
    transform: translateY(90px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
